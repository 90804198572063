import { Component, HostListener, OnInit, signal } from '@angular/core';
import {
  NavigationEnd,
  NavigationSkipped,
  NavigationStart,
  Router,
} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { StateService } from '../state/state.service';

interface NavItem {
  name: string;
  link?: string;
  icon?: string;
  condition?: (type: 'desktop' | 'mobile') => boolean;
}

@Component({
  selector: 'fini-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent implements OnInit {
  constructor(private router: Router, private state: StateService) {}

  mobOpen$ = new BehaviorSubject(false);
  smallNav = signal('');
  override = signal(false);

  navs: NavItem[] = [
    {
      name: 'Dashboard',
      link: '/dashboard',
      icon: 'dashboard',
    },

    {
      name: 'Licenses',
      link: '/licenses',
      icon: 'shopping_cart',
    },
    {
      name: 'Configs',
      link: '/configs',
      icon: 'code',
    },
    {
      name: 'Logs',
      link: '/logs',
      icon: 'assignment',
    },
    {
      name: 'Identities',
      link: '/identities',
      icon: 'fingerprint',
    },
    {
      name: 'Apollo',
      link: '/apollo',
      icon: 'sports_esports',
    },
    {
      name: 'Whitelist',
      link: '/whitelist',
      icon: 'checklist',
    },
    {
      name: 'Webhooks',
      link: '/webhooks',
      icon: 'send',
    },
    {
      name: 'Triggers',
      link: '/triggers',
      icon: 'local_fire_department',
    },
    {
      name: 'Threats',
      link: '/threats',
      icon: 'warning',
    },
    {
      name: 'Docs',
      link: '/docs',
      icon: 'menu_book',
    },
    {
      name: 'Users',
      link: '/users',
      icon: 'supervised_user_circle',
    },
    {
      name: 'Profile',
      link: '/profile',
      icon: 'insert_emoticon',
      condition: (type: 'desktop' | 'mobile') => type == 'mobile',
    },
    {
      name: 'Translate',
      link: '/dashboard/i18n',
      icon: 'translate',
      condition: () =>
        this.state.get('user')?.permissions?.includes('TRANSLATE'),
    },
    {
      name: 'Admin',
      link: '/admin',
      icon: 'accessible_forward',
      condition: () => this.state.get('user')?.permissions?.includes('ADMIN'),
    },
  ];
  groups: {
    [key: string]: { open: boolean };
  } = {};

  openGroup(group: string) {
    if (this.groups[group]) {
      this.groups[group].open = !this.groups[group].open;
    } else {
      this.groups[group] = { open: true };
    }
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.override.set(true);
      }
      if (
        event instanceof NavigationEnd ||
        event instanceof NavigationSkipped
      ) {
        setTimeout(() => {
          // I dislike this solution, but otherwise it still runs the animation
          this.override.set(false);
        }, 100);
      }
    });
  }

  toggleMob(state: boolean) {
    this.mobOpen$.next(state);
  }

  navigate(url: string) {
    this.router.navigateByUrl(url);
    this.toggleMob(false);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const scrollPosition = window.scrollY;

    // clamp the percentage between 0 and 128px from the top
    if (scrollPosition >= 135) {
      this.smallNav.set('smallnav');
    } else if (scrollPosition < 40) {
      this.smallNav.set('');
    }
  }
}
