<div class="head">
  <div class="container d-flex align-items-center">
    <img src="/assets/img/logo.png" alt class="header-item" />

    <div class="header-item account">
      <a
        routerLink="/profile"
        class="header-link"
        style="max-height: 24px"
        id="header-profile"
      >
        <i class="material-symbols-outlined">account_circle</i>
        <span class="text" *ngIf="'user' | state | async as user">
          {{ user.username }}
        </span>
      </a>
    </div>
    <div
      class="header-item dropdown"
      (click)="toggleServerList(!serverList())"
      #dropdownServer
    >
      <i class="material-symbols-outlined">expand_more</i>
      <span class="text">{{ server?.name }}</span>

      <div (click)="$event.stopPropagation()">
        <div class="list" *ngIf="serverList()">
          <div class="top-break">
            <div class="header-item" (click)="toggleServerList(!serverList())">
              <i class="material-symbols-outlined">expand_more</i>
              <span class="text">{{ server?.name }}</span>
            </div>
          </div>
          <div class="server-list-search" *ngIf="servers.length > 5">
            <input
              autofocus
              type="text"
              placeholder="Search"
              [ngModel]="filterServers()"
              (ngModelChange)="filterServers.set($event)"
            />
            <i class="material-symbols-outlined">search</i>
          </div>
          <div class="inner">
            <cdk-virtual-scroll-viewport
              class="viewport"
              itemSize="26"
              *ngIf="servers.length >= 25"
            >
              <p
                *cdkVirtualFor="
                  let srv of servers
                    | filterObjectByField : 'name' : filterServers()
                "
                class="item"
                [class.bold]="srv.id == server?.id"
                (click)="changeServer(srv)"
                [title]="srv.name"
              >
                {{ srv.name }}
              </p>
            </cdk-virtual-scroll-viewport>

            <div *ngIf="servers.length < 25">
              <p
                *ngFor="
                  let srv of servers
                    | filterObjectByField : 'name' : filterServers()
                "
                [class.bold]="srv.id == server?.id"
                (click)="changeServer(srv)"
              >
                {{ srv.name }}
              </p>
              <p
                *ngIf="
                  (servers | filterObjectByField : 'name' : filterServers())
                    .length < 1
                "
                class="no-results"
              >
                No Results
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-actions">
      <div
        class="header-item less-padding dropdown"
        (click)="toggleMenu(!menu())"
        #dropdownMenu
      >
        <i class="material-symbols-outlined">menu</i>
        <ng-container *ngIf="unreadCount()">
          <div class="unread">
            <div class="count">{{ unreadCount() }}</div>
          </div>
        </ng-container>
        <div (click)="$event.stopPropagation()">
          <div class="unified list w-auto" *ngIf="menu()">
            <div class="top-break">
              <div class="header-item" (click)="toggleMenu(!menu())">
                <span class="text">{{
                  'generic.menu' | translate | uppercase
                }}</span>
                <i class="material-symbols-outlined">menu</i>
              </div>
            </div>
            <div class="inner">
              <!-- Language selector -->
              <div class="menu-seperator" (click)="toggleMenuItem('languages')">
                <span class="text">
                  <i class="material-symbols-outlined"> translate </i>
                  {{ 'generic.language-select' | translate }}</span
                >
                <i
                  class="material-symbols-outlined"
                  [class.flipped]="show.languages()"
                >
                  expand_more
                </i>
              </div>
              <div class="items" [class.show]="show.languages()">
                <div
                  *ngFor="let language of languages"
                  [class.bold]="language == currentLanguage()"
                  (click)="changeLanguage(language)"
                >
                  {{ 'langs.' + language | translate }}
                </div>
              </div>
              <div class="menu-seperator" (click)="toggleMenuItem('themes')">
                <span class="text">
                  <i class="material-symbols-outlined">
                    {{
                      ('theme' | state | async) == 'light'
                        ? 'dark_mode'
                        : 'light_mode'
                    }}</i
                  >
                  {{ 'generic.theme-select' | translate }}</span
                >
                <i
                  class="material-symbols-outlined"
                  [class.flipped]="show.themes()"
                >
                  expand_more
                </i>
              </div>
              <div class="items" [class.show]="show.themes()">
                <div (click)="toggleTheme()">
                  <span>{{
                    ('theme' | state | async) == 'light'
                      ? 'Dark mode'
                      : 'Light mode'
                  }}</span>
                </div>
              </div>
              <div
                class="menu-seperator"
                (click)="toggleMenuItem('notifications')"
              >
                <span class="text">
                  <i class="material-symbols-outlined"> notifications </i>

                  Notifications
                  {{ unreadCount() ? '(' + unreadCount() + ')' : '' }}
                </span>
                <i
                  class="material-symbols-outlined"
                  [class.flipped]="show.notifications()"
                >
                  expand_more
                </i>
              </div>
              <div class="items" [class.show]="show.notifications()">
                <div
                  class="notification disabled"
                  *ngIf="notifications().length === 0; else clearNotifications"
                >
                  <div class="message text-center">
                    {{ 'generic.no-notifications' | translate }}
                  </div>
                </div>
                <ng-template #clearNotifications>
                  <div class="notification" (click)="clearAllNotifications()">
                    <div class="message text-center">
                      {{ 'generic.clear-notifications' | translate }}
                    </div>
                  </div>
                </ng-template>
                <div
                  class="notification"
                  *ngFor="let notification of notifications()"
                  [class.read]="notification.status == 'read'"
                  (click)="visitNotification(notification)"
                >
                  <div class="grid row-cols-2">
                    <div class="title col-9">
                      {{ notification.title }}
                    </div>
                    <span class="badge col-3">{{
                      notification.created_at * 1000 | timeDistance
                    }}</span>
                  </div>
                  <div class="message wrap-pre">
                    {{ notification.message }}
                  </div>
                  <div class="overlay"></div>
                </div>
              </div>
              <div class="menu-seperator" (click)="toggleMenuItem('guides')">
                <span class="text">
                  <i class="material-symbols-outlined"> help </i>
                  {{ 'generic.guide.title' | translate }}
                </span>
                <i
                  class="material-symbols-outlined"
                  [class.flipped]="show.guides()"
                >
                  expand_more
                </i>
              </div>
              <div class="items" [class.show]="show.guides()">
                <div (click)="startGuide('welcome')">
                  <span>{{ 'generic.guide.welcome' | translate }}</span>
                </div>
                <div (click)="startGuide('setup')">
                  <span>{{ 'generic.guide.setup' | translate }}</span>
                </div>
              </div>
              <div class="menu-seperator items show" (click)="logout()">
                <span class="text">
                  <i class="material-symbols-outlined"> exit_to_app </i>
                  {{ 'generic.logout' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
